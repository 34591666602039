import clipBoardCircled from '@assets/images/ordering-clipboard-circled.svg';
import clipBoard from '@assets/images/ordering-clipboard.svg';
import cart from '@assets/images/ordering-cart.svg';
import checkmark from '@assets/images/ordering-checkmark.svg';

export const Sidebar = ({checkoutStep, setCheckoutStep}) => {
    return (
        <aside className="sidebar">
            <ul className="sidebar__list">
                <li className={"sidebar__item sidebar__item--text-white" + (checkoutStep === 'checkout' ? ' clickable' : '')}
                    onClick={() => {
                        if (checkoutStep === 'checkout') {
                            setCheckoutStep('order');
                        }
                    }}>
                    <div className="icon-container">
                        <img className="icon--circled" src={clipBoardCircled} alt="clipboard" />
                        <img className="icon--desktop" src={clipBoard} alt="clipboard" />
                    </div>
                    ORDER
                </li>
                <div className={"sidebar__divider "
                    + (checkoutStep === 'checkout' || checkoutStep === 'confirm' ? 'white' : '')}>
                </div>
                <li className={"sidebar__item "
                    + (checkoutStep === 'checkout' || checkoutStep === 'confirm' ? 'sidebar__item--text-white' : '')}>
                    <div className="icon-container">
                        <img className="sidebar-icon" src={cart} alt="shopping cart" />
                    </div>
                    CHECKOUT
                </li>
                <div className={"sidebar__divider " + (checkoutStep === 'confirm' ? 'white' : '')}>
                </div>
                <li className={"sidebar__item " + (checkoutStep === 'confirm' ? 'sidebar__item--text-white' : '')}>
                    <div className="icon-container">
                        <img className="sidebar-icon" src={checkmark} alt="check mark" />
                    </div>
                    CONFIRMATION
                </li>
            </ul>
        </aside>
    )
}
