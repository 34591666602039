export const StorageKeys = {
    checkoutStep: 'checkoutStep',
    initialFormData: 'initialFormData',
    needDate: 'storedNeedDate',
    shippingIndex: 'ship_tostoredSelectedOptionIndex',
    selectedInstructionOption: 'ship_instructionstoredSelectedOption',
    selectedShippingOption: 'ship_tostoredSelectedOption',
    selectedShippingOptionIndex: 'ship_tostoredSelectedOptionIndex',
    products: 'products',
}
