import dropdownArrow from '@assets/images/ordering-dropdown-arrow.svg';
import ProductsService from "@services/ProductsService";
export const SearchProductModal = ({active, setResults, closeModal}) => {
    return (
        <div className={"modal search-modal " + (active ? 'active' : '')}>
            <form className="form modal__content">
                <div className="modal__heading-container">
                    <h2 className="heading modal__heading">Search Product</h2>
                    <div className="modal__button-container">
                        <button
                            id="closeSearchModalBtn"
                            onClick={() => closeModal()}
                            className="button button--close" type="button">×</button>
                    </div>
                </div>
                <div className="modal__form-content">
                    <div className="form__row">
                        <div className="form__item dropdown">
                            <div className="dropdown__item-container">
                                <label className="form__label" htmlFor="">ENGINE MFG</label>
                                <input className="form__input " />
                            </div>
                            <button className="button dropdown__button">
                                <img src={dropdownArrow} alt="dropdown menu icon" />
                            </button>
                        </div>
                        <div className="form__item dropdown">
                            <div className="dropdown__item-container">
                                <label className="form__label" htmlFor="">MODEL NO</label>
                                <input className="form__input " />
                            </div>
                            <button className="button dropdown__button">
                                <img src={dropdownArrow} alt="dropdown menu icon" />
                            </button>
                        </div>
                    </div>
                    <div className="form__row">
                        <div className="form__item dropdown">
                            <div className="dropdown__item-container">
                                <label className="form__label" htmlFor="">GAS TYPE</label>
                                <input className="form__input " />
                            </div>
                            <button className="button dropdown__button">
                                <img src={dropdownArrow} alt="dropdown menu icon" />
                            </button>
                        </div>
                        <div className="form__item dropdown">
                            <div className="dropdown__item-container">
                                <label className="form__label" htmlFor="">PRESSURE</label>
                                <input className="form__input " />
                            </div>
                            <button className="button dropdown__button">
                                <img src={dropdownArrow} alt="dropdown menu icon" />
                            </button>
                        </div>
                        <div className="form__item dropdown">
                            <div className="dropdown__item-container">
                                <label className="form__label" htmlFor="">ROTATION</label>
                                <input className="form__input " />
                            </div>
                            <button className="button dropdown__button">
                                <img src={dropdownArrow} alt="dropdown menu icon" />
                            </button>
                        </div>
                    </div>
                    <div className="form__row">
                        <div className="form__item dropdown">
                            <div className="dropdown__item-container">
                                <label className="form__label" htmlFor="">ENGAGEMENT</label>
                                <input className="form__input " />
                            </div>
                            <button className="button dropdown__button">
                                <img src={dropdownArrow} alt="dropdown menu icon" />
                            </button>
                        </div>
                        <button className="button search-modal__button" type="button"
                                onClick={() => {
                                    ProductsService.searchProduct('1-188').then(data => {
                                        setResults(data.products);
                                        closeModal();
                                    });
                                }}>Search
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}
