import {useState} from "react";
import {Dropdown} from "@components/Form/Dropdown";
import DatePicker from "react-datepicker";

export const InitialInfoForm = ({formData, setFormData, readOnly}) => {
    const [shippingInstructions, setShippingInstructions] = useState([
        {label: 'Shipping 01', value: '1'},
        {label: 'Shipping 02', value: '2'},
        {label: 'Other', value: '3'}
    ]);

    const [orderDate, setOrderDate] = useState(new Date());

    const storedNeedDate = sessionStorage.getItem('storedNeedDate');
    const [needDate, setNeedDate] = useState(storedNeedDate);

    const updateInfo = (key, value) => {
        const updatedInfo = structuredClone(formData);

        updatedInfo[key] = value;

        if (key === 'order_date') {
            setOrderDate(value);
        } else if (key === 'need_date') {
            setNeedDate(value);
            sessionStorage.setItem('storedNeedDate', value);
        } else if (key === 'shipping_instruction') {
            for (const instructionValue of Object.values(shippingInstructions)) {
                if (instructionValue.value == value) {
                    updatedInfo.shipping_instruction_text = instructionValue.label;
                }
            }
        }

        setFormData(updatedInfo);

        sessionStorage.setItem('initialFormData', JSON.stringify(updatedInfo));
    }

    return (
        <div className="ordering-form form-main">
            <div className="form__item form-main__item">
                <label className="form__label" htmlFor="order-date">Order Date</label>

                <DatePicker readOnly={true} selected={orderDate} className="form__input" id="order-date" />
            </div>

            <div className={"form__item form-main__item" + (readOnly ? '' : ' white-bg')}>
                <label className="form__label" htmlFor="customer-po">Customer PO</label>
                <input className="form__input" id="customer-po" value={formData.customer_po} onChange={e => {
                    updateInfo('customer_po', e.target.value);
                }} required={true} readOnly={readOnly} />
            </div>

            <div className="form__item form-main__item">
                <label className="form__label" htmlFor="sales-person">Sales Person</label>
                <input readOnly={true} value={''} className="form__input" id="sales-person" onChange={e => {
                    updateInfo('sales_person', e.target.value);
                }} />
            </div>

            <Dropdown
                label={'Shipping Instruction'}
                options={shippingInstructions}
                classList={'form__item form-main__item dropdown'}
                onChange={index => {
                    updateInfo('shipping_instruction', index);
                }}
                readOnly={readOnly}
                name={'ship_instruction'}
            />

            <div className={"form__item form-main__item" + (readOnly ? '' : ' white-bg')}>
                <label className="form__label" htmlFor="need-date">Need Date</label>

                <DatePicker readOnly={readOnly} selected={needDate} className="form__input" id="need-date" minDate={new Date()}
                            onChange={(date) => updateInfo('need_date', date)} />
            </div>

            {formData?.shipping_instruction == 2 && (
                <div className={"form__item form-main__item" + (readOnly ? '' : ' white-bg')}>
                    <label className="form__label" htmlFor="shipping-note">Shipping Note</label>
                    <textarea readOnly={readOnly} required={true} className="form__textarea form__input" id="shipping-note"
                              placeholder="Your instruction..." onChange={e => {
                        updateInfo('shipping_note', e.target.value);
                    }}>{formData.shipping_note}</textarea>
                </div>
            )}

            {formData?.shipping_instruction != 2 && (
                <div>
                </div>
            )}
        </div>
    )
}
