import {Order} from '@screens/Order/Order';
/**
 * Authentication Routes (Login, Forgot Password, etc.)
 */

export const authRoutes = [
	// {
	// 	path: '/login',
	// 	component: Login,
	// 	exact: true,
	// },
	// {
	// 	path: '/forgot',
	// 	component: Forgot,
	// 	exact: true,
	// },
	// {
	// 	path: '/reset',
	// 	component: Reset,
	// 	exact: true,
	// }
];

/**
 * Public Routes
 */

export const publicRoutes = [
	{
		path: '/',
		element: <Order />,
		exact: true,
	}
];

/**
 * Initial Route - Redirect after login
 */

export const initialRoute = '/';
