import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import './styles/style.scss';
import 'react-datepicker/dist/react-datepicker.css';
import { store } from '@store';
import SiteWrapper from './SiteWrapper';


function App() {
	return (
		<ReduxProvider store={store}>
			<SiteWrapper />
		</ReduxProvider>
	);
}

export default App;
