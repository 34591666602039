import {useEffect, useState} from "react";

export const BillToForm = ({address}) => {
    const [addressStreet, setAddressStreet] = useState('');

    useEffect(() => {
        let billTo = address.address_street_1;
        if (address.address_street_2) {
            billTo += ', ' + address.address_street_2;
        }

        setAddressStreet(billTo);
    }, [address])

    return (
        <div className="ordering-form form-billing">
            <h2 className="form__heading">BILL TO</h2>
            <div className="form__content">
                <div className="form__row">
                    <div className="form__item form__item--medium">
                        <label className="form__label" htmlFor="sales-person-billing">Sales Person</label>
                        <input className="form__input" id="sales-person-billing" name="sales-person-billing"
                               value={address?.contact_first_name} />
                    </div>
                    <div className="form__item form__item--large">
                        <label className="form__label" htmlFor="bill-to-billing">Bill To</label>
                        <input className="form__input" id="bill-to-billing" name="bill-to-billing" value={addressStreet} />
                    </div>
                </div>
                <div className="form__row">
                    <div className="form__item form__item--medium">
                        <label className="form__label" htmlFor="state-billing">State</label>
                        <input className="form__input" id="state-billing" name="state-billing" value={address.state_name} />
                    </div>
                    <div className="form__item form__item--small">
                        <label className="form__label" htmlFor="postal-code-billing">Postal Code</label>
                        <input className="form__input" id="postal-code-billing" name="postal-code-billing" value={address.address_zip} />
                    </div>
                    <div className="form__item form__item--medium">
                        <label className="form__label" htmlFor="country-billing">Country</label>
                        <input className="form__input" id="country-billing" name="country-billing" value={address.country_name} />
                    </div>
                </div>
                <div className="form__row">
                    <div className="form__item form__item--medium">
                        <label className="form__label" htmlFor="company-billing">Company</label>
                        <input className="form__input" id="company-billing" name="company-billing" value={address.contact_company_title} />
                    </div>
                    <div className="form__item form__item--large">
                        <label className="form__label" htmlFor="email-billing">Email</label>
                        <input className="form__input" id="email-billing" name="email-billing" value={address.contact_email} />
                    </div>
                </div>
            </div>
        </div>
    )
}
