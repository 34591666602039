import {Header} from "@components/Header/Header";
import {Sidebar} from "@components/Sidebar/Sidebar";
import {OrderForm} from "@components/Order/OrderForm";
import {useEffect, useState} from "react";
import {StorageKeys} from "@utilities/StorageKeys";
import {useSearchParams} from "react-router-dom";

export const Order = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        // if id timestamp and signature are set in url, store them and remove from url.
        if (searchParams.has('id') && searchParams.has('signature')) {
            localStorage.setItem('auth_secret', searchParams.get('signature'));
            localStorage.setItem('auth_id', searchParams.get('id'));
            localStorage.setItem('auth_timestamp', searchParams.get('timestamp'));

            searchParams.delete('id');
            searchParams.delete('signature');
            searchParams.delete('timestamp');

            setSearchParams(searchParams);
        }
    }, []);

    let storedStep = localStorage.getItem(StorageKeys.checkoutStep);
    const [checkoutStep, setCheckoutStep] = useState(storedStep ? storedStep : 'order');

    useEffect(() => {
        localStorage.setItem(StorageKeys.checkoutStep, checkoutStep);
    }, [checkoutStep])

    return (
        <>
            <Header />

            <div className="main-wrapper">
                <Sidebar checkoutStep={checkoutStep} setCheckoutStep={setCheckoutStep} />

                <div className={"content-wrapper " + (checkoutStep === 'order' || checkoutStep === 'checkout' ? 'pad-bottom' : '')}>
                    <OrderForm checkoutStep={checkoutStep} setCheckoutStep={setCheckoutStep} />
                </div>
            </div>
        </>
    )
}
