import Axios from 'axios';
import { store } from '@store';

export default () => {
	const state = store.getState();

	// check session storage for auth secret and id
	const secret = localStorage.getItem('auth_secret');
	const id = localStorage.getItem('auth_id');
	const timestamp = localStorage.getItem('auth_timestamp')

	const instance = Axios.create({
		baseURL: '/',
		withCredentials: false,
		headers: {
			common: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Auth-Token': state.auth.token,
				'id': id,
				'signature': secret,
				'timestamp': timestamp,
			},
		},
	});

	if (process.env.NODE_ENV === 'development') {
		instance.interceptors.request.use(async config => {
			if (config.params) {
				config.params.XDEBUG_SESSION_START = 'PHPSTORM';
			} else {
				config.params = {
					XDEBUG_SESSION_START: 'PHPSTORM',
				};
			}

			return config;
		});
	}

	instance.interceptors.response.use(
		(response) => {
			if (response.data.message) {
				console.log('responseMessage: ', response.data.message);
			}

			return response;
		},
		(error) => {
			if (error?.response?.status !== 401) {
				// don't alert 401
				if (error?.response?.data?.message) {
					localStorage.setItem('error', JSON.stringify(error?.response?.data?.message));
					window.dispatchEvent(new Event("local-error"));
				} else if (error?.response?.data?.meta?.message) {
					localStorage.setItem('error', JSON.stringify(error?.response.data.meta.message));
					window.dispatchEvent(new Event("local-error"));
				}
			}

			if (error.response) {
				console.log('responseError: ', error.response);
			} else {
				console.log('errorMessage: ', error.message);
			}

			return Promise.reject(error);
		},
	);

	return instance;
};
