import Http from "../utilities/Http";
import {API_ROOT} from "@config";

export default {
    async getBillingAddress() {
        let response = await Http().get(`${API_ROOT}/addresses/default-billing`);

        return response.data;
    },
    async getShippingAddresses() {
        let response = await Http().get(`${API_ROOT}/addresses/shipping-addresses`);

        return response.data;
    }
}
