import {Dropdown} from "@components/Form/Dropdown";
import {useEffect, useState} from "react";
import {StorageKeys} from "@utilities/StorageKeys";

export const ShipToForm = ({addressOptions, selectedShippingOption, setSelectedShippingOption, readOnly}) => {
    const [shippingOptions, setShippingOptions] = useState([]);

    const storedSelectedIndex = sessionStorage.getItem(StorageKeys.selectedShippingOptionIndex);
    const [selectedShippingIndex, setSelectedShippingIndex] = useState(null);

    useEffect(() => {
        // format the shipping options from address options and then set
        let options = [];
        for (let i = 0; i < addressOptions.length; i++) {
            let street = addressOptions[i].address_street_1;

            if (addressOptions[i].address_street_2) {
                street += ', ' + addressOptions[i].address_street_2;
            }

            let contact = addressOptions[i].contact_first_name;

            if (addressOptions[i].contact_last_name) {
                contact = addressOptions[i].contact_first_name + ' ' + addressOptions[i].contact_last_name;
            }

            const addressLine = contact + ' ' + street + ' ' + addressOptions[i].address_city;

            options.push({
                label: addressLine,
                value: i,
            });
        }

        setShippingOptions(options);

        setSelectedShippingIndex(storedSelectedIndex);
        setSelectedShippingOption(addressOptions[storedSelectedIndex]);
    }, [addressOptions]);

    useEffect(() => {
        setSelectedShippingOption(addressOptions[selectedShippingIndex]);
    }, [selectedShippingIndex])

    return (
        <div className="ordering-form form-shipping">
            <h2 className="form__heading">SHIP TO</h2>
            <div className="form__content">
                <div className="form__row">
                    <Dropdown
                        label={'Ship To'}
                        options={shippingOptions}
                        classList={'form__item form__item--full-width dropdown'}
                        onChange={selected => {
                            setSelectedShippingIndex(selected);
                        }}
                        readOnly={readOnly}
                        name={'ship_to'}
                    />
                </div>
                <div className="form__row">
                    <div className="form__item form__item--medium">
                        <label className="form__label" htmlFor="state-shipping">State</label>
                        <input className="form__input" id="state-shipping" name="state-shipping"
                            value={selectedShippingOption?.state_name}/>
                    </div>
                    <div className="form__item form__item--small">
                        <label className="form__label" htmlFor="postal-code-shipping">Postal Code</label>
                        <input className="form__input" id="postal-code-shipping" name="postal-code-shipping"
                            value={selectedShippingOption?.address_zip}/>
                    </div>
                    <div className="form__item form__item--medium">
                        <label className="form__label" htmlFor="country-shipping">Country</label>
                        <input className="form__input" id="country-shipping" name="country-shipping"
                            value={selectedShippingOption?.country_name}/>
                    </div>
                </div>
                <div className="form__row">
                    <div className="form__item form__item--medium">
                        <label className="form__label" htmlFor="company-shipping">Company</label>
                        <input className="form__input" id="company-shipping" name="company-shipping"
                               value={selectedShippingOption?.contact_company_title} />
                    </div>
                    <div className="form__item form__item--large">
                        <label className="form__label" htmlFor="email-shipping">Email</label>
                        <input className="form__input" id="email-shipping" name="email-shipping"
                               value={selectedShippingOption?.contact_email} />
                    </div>
                </div>
            </div>
        </div>
    )
}
