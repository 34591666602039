import {useEffect, useRef, useState} from "react";

export const Dropdown = ({label, classList, options, onChange, readOnly, name}) => {
    const dropdownRef = useRef(null);
    const [dropdownActive, setDropdownActive] = useState(false);
    const storedSelectedOption = sessionStorage.getItem(name + 'storedSelectedOption');
    const [selectedOption, setSelectedOption] = useState(storedSelectedOption ? JSON.parse(storedSelectedOption) : {
        value: '',
        label: '',
    });

    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownActive(false);
            } else {
                setDropdownActive(true);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);

        if (readOnly) {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef, readOnly])

    return (
        <div ref={dropdownRef} className={classList + (dropdownActive ? ' active' : '') + (readOnly ? ' readonly' : ' white-bg')}>
            <div className="dropdown__item-container">
                <label className="form__label" htmlFor="shipping-instruction">{label}</label>
                <input className="form__input" id="shipping-instruction" onChange={() => onChange(selectedOption)} value={selectedOption.label} required={true} />
            </div>

            {!readOnly && (
                <div className="dropdown__button">
                    <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path id="Vector"
                              d="M5.89559 4.9073C5.94745 4.83015 5.98438 4.75465 6.03938 4.69803C7.42384 3.24873 8.80987 1.80107 10.1967 0.35505C10.5974 -0.0626699 11.1773 -0.0396912 11.4963 0.401828C11.7587 0.765384 11.7163 1.23481 11.3808 1.58605C10.3515 2.66359 9.32059 3.73867 8.2905 4.81456C7.70827 5.42268 7.12683 6.03079 6.54382 6.63808C6.08024 7.12064 5.6253 7.12064 5.16408 6.63808C3.53683 4.9393 1.90958 3.24216 0.284688 1.54174C-0.200108 1.03456 -0.0429622 0.245081 0.583265 0.044017C0.925844 -0.0659526 1.22364 0.034169 1.47586 0.299245C2.12487 0.982041 2.77624 1.66237 3.4284 2.34189C4.23299 3.17979 5.03915 4.01687 5.89481 4.90648L5.89559 4.9073Z"
                              fill="black"/>
                    </svg>
                </div>
            )}

            <div className="dropdown__container">
                <div className="dropdown__list" onClick={() => {
                    if (!readOnly) {
                        setDropdownActive(false);
                    }
                }}>
                    {options.map((option, index) => {
                        return <div
                            key={'option-' + option.value + '-' + index} className="dropdown__option"
                            onClick={() => {
                                setSelectedOption(option);
                                sessionStorage.setItem(name + 'storedSelectedOption', JSON.stringify(option));
                                sessionStorage.setItem(name + 'storedSelectedOptionIndex', index);

                                onChange(index);
                            }}
                        >
                            {option.label}
                        </div>
                    })}
                </div>
            </div>
        </div>
    )
}
