import { createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { fetchUser } from '@store/thunks/fetch-user';

const initialState = {
	token: null,
	user: null,
	authSettled: false,
};

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setToken(state, action) {
			return {
				...state,
				token: action.payload,
			};
		},
		setUser(state, action) {
			return {
				...state,
				user: action.payload,
			};
		},
		logout() {
			localStorage.removeItem('persist:auth');
			return {
				...initialState,
				authSettled: true,
			};
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchUser.pending, (state) => {
				return {
					...state,
					isFetchingUser: true,
				};
			})
			.addCase(fetchUser.fulfilled,  (state, action) => {
				return {
					...state,
					user: action.payload.user,
					authSettled: true,
					isFetchingUser: false,
				};
			})
			.addCase(fetchUser.rejected, () => {
				return {
					...initialState,
					authSettled: true,
					isFetchingUser: false,
				};
			})
	},
});

export const persistConfig = {
	key: 'auth',
	storage,
	blacklist: ['authSettled'],
};

export const { setToken, setUser, logout } = authSlice.actions;
export default persistReducer(persistConfig, authSlice.reducer);
