import {configureStore} from '@reduxjs/toolkit';
import {
	persistStore,
	// FLUSH,
	// REHYDRATE,
	// PAUSE,
	// PERSIST,
	// PURGE,
	// REGISTER,
} from 'redux-persist';

import authReducers from './reducers/auth';

export const store = configureStore({
	reducer: {
		auth: authReducers,
	},
	middleware: (getDefaultMiddleware) => getDefaultMiddleware({
		serializableCheck: false,
	}),
});

export const persistor = persistStore(store, null);
