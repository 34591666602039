import { PersistGate } from 'redux-persist/integration/react';
import Router from './Router';
import { persistor } from '@store';

if (process.env.NODE_ENV === 'development') {
	require('./debug');
}

function SiteWrapper() {
	/**
	 * Fetch static data
	 */


	return (
		<div className="site-wrapper">
			<PersistGate loading={null} persistor={persistor}>
				<Router />
			</PersistGate>
		</div>
	);
}

export default SiteWrapper;
