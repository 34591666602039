import dropdownArrow from '@assets/images/ordering-dropdown-arrow.svg';
import ProductsService from "@services/ProductsService";
export const ResultsModal = ({active, results, closeModal, addResultProduct}) => {
    return (
        <>
            <div className={"modal results-modal hidden--desktop " + (active ? 'active' : '')}>
                <form className="form modal__content">
                    <div className="modal__heading-container">
                        <h2 className="heading modal__heading">Results</h2>
                        <button id="closeResultsModalBtn" className="button button--close"
                                type="button">&times;</button>
                    </div>
                    {results.map((result, index) => {
                        return (
                            <div className="modal__form-content" key={'tablet-modal-result-' + index}>
                                <div className="form__row-container">
                                    <div className="form__row">
                                        <div className="form__item">
                                            <label className="form__label" htmlFor="">Make</label>
                                            <input className="form__input" id="" name="" />
                                        </div>
                                        <div className="form__item">
                                            <label className="form__label" htmlFor="">Model</label>
                                            <input className="form__input" id="" name="" />
                                        </div>
                                    </div>
                                    <div className="form__row">
                                        <div className="form__item">
                                            <label className="form__label" htmlFor="">Cylinders</label>
                                            <input className="form__input" id="" name="" />
                                        </div>
                                        <div className="form__item">
                                            <label className="form__label" htmlFor="">Cid</label>
                                            <input className="form__input" id="" name="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="form__row-container">
                                    <div className="form__row">
                                        <div className="form__item">
                                            <label className="form__label" htmlFor="">Liters</label>
                                            <input className="form__input" id="" name="" />
                                        </div>
                                        <div className="form__item">
                                            <label className="form__label" htmlFor="">TDI Model</label>
                                            <input className="form__input" id="" name="" value={result.model} />
                                        </div>
                                    </div>
                                </div>
                                <div className="form__row-container">
                                    <div className="form__row">
                                        <div className="form__item">
                                            <label className="form__label" htmlFor="">Rotation</label>
                                            <input className="form__input" id="" name="" />
                                        </div>
                                        <div className="form__item">
                                            <label className="form__label" htmlFor="">Fuel</label>
                                            <input className="form__input" id="" name="" />
                                        </div>
                                    </div>
                                    <div className="form__row">
                                        <div className="form__item">
                                            <label className="form__label" htmlFor="">Pinion</label>
                                            <input className="form__input" id="" name="" />
                                        </div>
                                        <div className="form__item">
                                            <label className="form__label" htmlFor="">SAE</label>
                                            <input className="form__input" id="" name="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="results-modal__button-container">
                                    <button className="button results-modal__button" type={'button'} onClick={() => {
                                        ProductsService.getProduct(result.part_number).then(data => {
                                            addResultProduct({
                                                part_number: data.product.part_number,
                                                description: data.product.description,
                                                price: data.product.price,
                                                weight: data.product.weight,
                                                quantity: 1,
                                                dimensions: data.product.dimensions,
                                                model: data.product.model,
                                                section: data.product.section,
                                                column1: data.product.column1,
                                                isEditing: false,
                                            });

                                            closeModal();
                                        });
                                    }}> + Add Product</button>
                                </div>
                            </div>
                        )
                    })}

                </form>
            </div>
            <div className={"modal results-modal-desktop visible--desktop " + (active ? 'active' : '')}>
                <div className="modal__content">
                    <div className="modal__heading-container">
                        <h2 className="heading modal__heading">Results</h2>
                        <div className="modal__button-container">
                            <button id="closeResultsModalDesktopBtn" className="button button--close"
                                    onClick={() => closeModal()}
                                    type="button">&times;</button>
                        </div>
                    </div>
                    <table className="table">
                        <thead className="table-head">
                        <tr>
                            <th className="table__heading table__heading--left-align">Make</th>
                            <th className="table__heading">Model</th>
                            <th className="table__heading">Cylinders</th>
                            <th className="table__heading">Cid</th>
                            <th className="table__heading table__heading--left-align">Liters</th>
                            <th className="table__heading table__heading--left-align">TDI Model</th>
                            <th className="table__heading">Rotation</th>
                            <th className="table__heading">Fuel</th>
                            <th className="table__heading">Pinion</th>
                            <th className="table__heading">SAE</th>
                        </tr>
                        </thead>
                        <tbody>
                        {results.map((result, index) => {
                            // limit to 3 for now. table breaks overflows with more.
                            if (index > 2) {
                                return;
                            }

                            return <tr key={'product-search-result-desktop-' + index}
                                       onClick={() => {
                                           ProductsService.getProduct(result.part_number).then(data => {
                                               console.log('dat', data);
                                               addResultProduct({
                                                   part_number: data.product.part_number,
                                                   description: data.product.description,
                                                   price: data.product.price,
                                                   weight: data.product.weight,
                                                   quantity: 1,
                                                   dimensions: data.product.dimensions,
                                                   model: data.product.model,
                                                   section: data.product.section,
                                                   column1: data.product.column1,
                                                   isEditing: false,
                                               });

                                               closeModal();
                                           });
                                       }}>
                                <td className="table__cell">CATERPILLAR</td>
                                <td className="table__cell">1140</td>
                                <td className="table__cell">8</td>
                                <td className="table__cell">522</td>
                                <td className="table__cell table__cell--left-align">8.60000038146973</td>
                                <td className="table__cell table__cell--left-align">{result.model}</td>
                                <td className="table__cell">RH</td>
                                <td className="table__cell">D</td>
                                <td className="table__cell">8/10P,12T</td>
                                <td className="table__cell">3</td>
                            </tr>
                        })}

                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}
