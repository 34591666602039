import {useEffect, useState} from "react";
import AddressesService from "@services/AddressesService";
import {InitialInfoForm} from "@components/Order/InitialInfoForm";
import {BillToForm} from "@components/Order/BillToForm";
import {ShipToForm} from "@components/Order/ShipToForm";
import {ProductListForm} from "@components/Order/ProductListForm";
import {SearchProductModal} from "@components/Modal/SearchProductModal";
import {ResultsModal} from "@components/Modal/ResultsModal";
import {formatPriceWithCommas} from "@utilities/Price";
import OrderService from "@services/OrderService";
import {StorageKeys} from "@utilities/StorageKeys";

export const OrderForm = ({checkoutStep, setCheckoutStep}) => {
    const storedInitialInfo = sessionStorage.getItem(StorageKeys.initialFormData);
    const [initialInfoData, setInitialInfoData] = useState( storedInitialInfo ? JSON.parse(storedInitialInfo) : {
        order_date: new Date(),
    });
    const [billToAddress, setBillToAddress] = useState({});
    const [shippingAddresses, setShippingAddresses] = useState([]);
    const [searchProductModalActive, setSearchProductModalActive] = useState(false);
    const [productSearchResults, setProductSearchResults] = useState([]);
    const [resultsModalActive, setResultsModalActive] = useState(false);

    const storedProductRows = sessionStorage.getItem(StorageKeys.products);
    const [productRows, setProductRows] = useState(storedProductRows ? JSON.parse(storedProductRows) : []);
    const [productEditingIndex, setProductEditingIndex] = useState(null);
    const [selectedShippingOption, setSelectedShippingOption] = useState({});

    useEffect(() => {
        AddressesService.getBillingAddress().then(data => {
            setBillToAddress(data.address);
        });

        AddressesService.getShippingAddresses().then(data => {
            setShippingAddresses(data.addresses);
        })
    }, []);

    const [totalPrice, setTotalPrice] = useState(0);
    useEffect(() => {
        let total = 0;
        for(const row of productRows) {
            if (row.distributor_product_price && row.quantity) {
                total += parseFloat(row.distributor_product_price * row.quantity);
            }
        }

        setTotalPrice(total);

    }, [productRows]);

    const submitOrder = () => {
        // gather order data..
        const orderData = {};

        orderData.details = initialInfoData;
        orderData.billing_address = billToAddress;
        orderData.shipping_address = selectedShippingOption;
        orderData.items = productRows;

        console.log('order', orderData);

        OrderService.submit(orderData).then(data => {
            setCheckoutStep('confirm');
        });
    };

    return (
        <>
            <main className="main-content">
                {checkoutStep === 'order' ? (
                    <h1 className="main-content__heading">Orders</h1>
                ) : (
                    checkoutStep === 'checkout' ? (
                        <>
                            <h1 className="main-content__heading">Checkout</h1>
                            <p>Please review this information to ensure it is accurate before placing your order.</p>
                        </>
                    ) : (
                        <>
                            <h1 className="main-content__heading">Order Confirmed</h1>
                            <p>Hello Joe, your order has been placed. Thank you for your business.</p>
                        </>
                    )
                )}


                <form action="#" id={'order_form'} onSubmit={e => {
                    console.log('submit');
                    e.preventDefault();

                    if (checkoutStep === 'order') {
                        setProductEditingIndex(null);
                        setCheckoutStep('checkout');

                        window.scrollTo(0, 0);
                    } else if (checkoutStep === 'checkout') {
                        // submit order
                        submitOrder();
                    }
                }}>
                    <InitialInfoForm formData={initialInfoData} setFormData={setInitialInfoData}
                                     readOnly={checkoutStep !== 'order'} />

                    <div className="form-container">
                        <BillToForm address={billToAddress} />

                        <ShipToForm addressOptions={shippingAddresses} selectedShippingOption={selectedShippingOption}
                                    setSelectedShippingOption={setSelectedShippingOption}
                                    readOnly={checkoutStep !== 'order'} />
                    </div>
                    <div className="ordering-form form-product">
                        <ProductListForm editingIndex={productEditingIndex} onClickSearch={(itemIndex) => {
                            setSearchProductModalActive(true);

                            setProductEditingIndex(itemIndex);
                        }} setEditingIndex={setProductEditingIndex} productRows={productRows} setProductRows={setProductRows}
                                         readOnly={checkoutStep !== 'order'} checkoutStep={checkoutStep} />
                    </div>
                </form>


                <SearchProductModal
                    active={searchProductModalActive}
                    closeModal={() => setSearchProductModalActive(false)}
                    setResults={setProductSearchResults}
                />

                <ResultsModal
                    active={productSearchResults.length}
                    closeModal={() => {setProductSearchResults([])}}
                    results={productSearchResults}
                    addResultProduct={product => {
                        productRows[productEditingIndex] = product;

                        setProductRows([...productRows]);
                        setProductEditingIndex(null);
                    }}
                />
            </main>

            {totalPrice !== 0 && checkoutStep !== 'confirm' && (
                <div className="main-footer">
                    <div>Total Amount: <span>${formatPriceWithCommas(totalPrice)}</span></div>
                    <button className="button" type={'submit'} form={'order_form'} id="button--black">
                        {checkoutStep === 'order' ? 'Place Order' : 'Confirm Order'}
                    </button>
                </div>
            )}

            {checkoutStep === 'confirm' && (
                <>
                    <div style={{paddingTop: 80}}></div>
                    <div className="main-footer hidden--desktop" id="main-footer--black">
                        <div>Total Amount: <span>${formatPriceWithCommas(totalPrice)}</span></div>
                    </div>

                    <div className="main-footer new-order">
                        <div>Total Amount: <span>${formatPriceWithCommas(totalPrice)}</span></div>
                        <button className="button" type={'button'} id="button--black" onClick={() => {
                            for (const key of Object.values(StorageKeys)) {
                                sessionStorage.removeItem(key);
                            }

                            setCheckoutStep('order');

                            window.location.reload();
                        }}>
                            New Order
                        </button>
                    </div>
                </>
            )}
        </>
    )
}
