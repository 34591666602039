import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Route, Routes } from 'react-router';
// import PrivateRoute from '@components/PrivateRoute';
// import AuthRoute from '@components/Auth/AuthRoute';
import {
	authRoutes,
	publicRoutes,
} from '@config/routes';
import { useSelector } from 'react-redux';

const Router = () => {

	return (
		<BrowserRouter>
			<div className="App">
				<Routes>
					{publicRoutes.map((route, r) => (
						<Route
							key={r}
							path={route.path}
							element={route.element}
							exact={!!route.exact}
						/>
					))}
				</Routes>
			</div>
		</BrowserRouter>
	);
};

export default Router;
