import { createAsyncThunk } from '@reduxjs/toolkit';
import { API_ROOT } from '@config';
import Http from '@utilities/Http';

export const fetchUser = createAsyncThunk('auth/fetch', async () => {
	return Http()
		.get(`${API_ROOT}/auth/validate`)
		.then((response) => ({
			user: response.data?.user,
			notifications: response.data?.notifications,
			addresses: response.data?.addresses,
		}));
});
