import Http from "../utilities/Http";
import {API_ROOT} from "@config";

export default {
    async submit(order) {
        let response = await Http().post(`${API_ROOT}/orders/submit`, order);

        return response.data;
    },
}
