import logo from '@assets/images/tdi-logo.svg';
import menuIcon from '@assets/images/ordering-menu.svg';

export const Header = () => {
    return (
        <header className="ordering-header">
            <img className="logo" src={logo} alt="TDI logo" />
            <nav className="navbar">
                <img className="nav-icon" src={menuIcon} alt="navigation menu" />
                <ul className="nav-menu">
                    <li className="nav-menu__item">
                        <a className="nav-menu__link"
                           href="https://www.tdi-airstarter.com/distributors/distributor-home"
                           target={'_blank'} rel={'noreferrer'}
                        >
                            DISTRIBUTOR
                        </a>
                    </li>
                    <li className="nav-menu__item">
                        <a className="nav-menu__link"
                           href="https://www.tdi-airstarter.com/distributor-profile"
                           target={'_blank'} rel={'noreferrer'}
                        >
                            MY PROFILE
                        </a>
                    </li>
                    <li className="nav-menu__item">
                        <a className="nav-menu__link"
                           href="https://www.tdi-airstarter.com/contact-us"
                           target={'_blank'} rel={'noreferrer'}
                        >
                            CONTACT
                        </a>
                    </li>
                </ul>
            </nav>
        </header>
    )
}
