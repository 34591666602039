import Http from "../utilities/Http";
import {API_ROOT} from "@config";

export default {
    async searchProduct(term) {
        let response = await Http().get(`${API_ROOT}/products/search-products`, {
            params: {
                term
            }
        });

        return response.data;
    },
    async getProduct(part_number) {
        let response = await Http().get(`${API_ROOT}/products/get-product`, {
            params: {
                part_number
            }
        });

        return response.data;
    },
}
